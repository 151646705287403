<template>
    <div class="login">
        <div class="box flex">
            <img src="shexie/img/decorate.png" alt="">
            <div class="right">
                <div class="title">忘 记 密 码</div>
                <div>
                    <img src="shexie/img/phone.png" alt="">
                    <span>手机号</span>
                    <input class="back" v-model="form.phone" placeholder="请输入您的手机号" width="100%"/>
                </div>
                <div>
                    <img src="shexie/img/code.png" alt="">
                    <span>验证码</span>
                    <div style="position: relative;">
                        <input class="back" v-model="form.code" placeholder="请输入验证码" width="100%"/>
                        <div @click="getcode" class="codes cursor">{{codeText}}</div>
                    </div>
                </div>
                <div>
                    <img src="shexie/img/password.png" alt="">
                    <span>密码</span>
                    <input class="back" v-model="form.password" placeholder="请输入您的新密码" width="100%" type="password"/>
                </div>
                <div class="btn" @click="modify_password">修改密码</div>
                <div class="t-center"><span @click="$router.push('/login')">返回登录页</span></div>
            </div>
            <img src="shexie/img/shan.png" alt="" class="imgs">
        </div>
    </div>
</template>

<script>
import {codes} from '@/api/commit'
import {forget_password} from '@/api/login'
export default {
    data() {
        return {
            form:{},
            codeText:'获取验证码',
            isSend:false,
            iTime:60,
            inter:null,
        };
    },
    created() {

    },
    mounted() {

    },
    methods: {
        //获取验证码
        getcode(){
            if(this.isSend) return;
            if(!this.form.phone){ 
                this.$message.warning('请输入手机号!'); 
                return;
            }
            codes({phone:this.form.phone}).then(res=>{
                if(res.code == 200){
                    this.startInter();
                }
            })
        },
        //修改密码
        modify_password(){
            forget_password(this.form).then(res=>{
                if(res.code==200){
                    this.$message.success(res.msg)
                    this.$router.push('/login')
                }
            })
        },
        startInter(){
            this.isSend = true;
            this.inter = setInterval(() => {
                if(this.iTime){
                    --this.iTime;
                    this.codeText = `${this.iTime} 秒`;
                }else{
                    this.destoryInter();
                    this.isSend = false;
                    this.iTime = 60;
                    this.codeText = '获取验证码';
                }
            }, 1000);
        },
        destoryInter(){
            if(this.inter){
                clearInterval(this.inter)
                this.inter = null;
            }
        },
    }
};
</script>

<style scoped lang="scss">
    .login{
        width: 100%;
        min-height: 100vh;
        background-image: url('/shexie/img/bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top:0.1px;
        .box{
            width: 1200px;
            margin:100px auto 0px;
            background: #FFF7F0;
            border-radius: 20px;
            box-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.2);
            position: relative;
            .imgs{
                position: absolute;
                right: 0;
                bottom: 0;
                margin: 0;
            }
            .right{
                width: 100%;
                padding: 40px 80px;
                z-index: 1;
                .title{
                    text-align: center;
                    font-weight: bold;
                    font-size: 30px;
                    color: #CD0000;
                    margin-bottom: 50px;
                }
                img{
                    margin-right:10px;
                }
                .btn{
                    width: 340px;
                    height: 48px;
                    text-align:center;
                    line-height: 48px;
                    color: #fff;
                    background: #CC0000;
                    border-radius: 24px;
                    cursor: pointer;
                    margin:80px auto 10px;
                }
                .t-center{
                    span{
                        color: #CD0000;
                        cursor: pointer;
                    }
                }
                .codes{
                    color: #CD0000;
                    position:absolute;
                    right: 0px;
                    top: 10px;
                }
                .back {
                    width: 100%;
                    margin:20px 0px;
                    border-bottom: 1px solid #999;
                    padding-bottom:8px;
                    font-size: 20px;
                }
            }
        }
    }
</style>
